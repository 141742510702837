@font-face {
  font-family: "Helvetica Now Display Bold";
  src: local("HelveticaNowDisplay-Bold"),
    url("../fonts/HelveticaNowDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Now Display Medium";
  src: local("HelveticaNowDisplay-Medium"),
    url("../fonts/HelveticaNowDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Now Display Regular";
  src: local("HelveticaNowDisplay-Regular"),
    url("../fonts/HelveticaNowDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Aeonik Regular";
  src: local("Aeonik-Regular"), url('../fonts/Aeonik-Regular.otf')  format("opentype");
}

@font-face {
  font-family: "Aeonik Medium";
  src: local("Aeonik-Medium"), url('../fonts/Aeonik-Medium.otf')  format("opentype");
}

@font-face {
  font-family: "Aeonik Bold";
  src: local("Aeonik-Bold"), url('../fonts/Aeonik-Bold.otf') format("opentype");
}

