@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@500;600&display=swap);
@font-face {
  font-family: "Helvetica Now Display Bold";
  font-weight: normal;
  src: local("HelveticaNowDisplay-Bold"),
    url("/fonts/HelveticaNowDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Now Display Medium";
  font-weight: normal;
  src: local("HelveticaNowDisplay-Medium"),
    url("/fonts/HelveticaNowDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Now Display Regular";
  font-weight: normal;
  src: local("HelveticaNowDisplay-Regular"),
    url("/fonts/HelveticaNowDisplay-Regular.ttf") format("truetype");
}

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 1rem;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure,
main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
}

a:hover {
  -webkit-text-decoration-skip: objects;
  text-decoration: underline;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Helveitca Now Display Medium", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

@media (min-width: 641px) {
  .n [class*="reveal-"] {
    opacity: 0;
    will-change: opacity, transform;
  }

  .n .reveal-from-top {
    transform: translateY(-10px);
  }

  .n .reveal-from-bottom {
    transform: translateY(10px);
  }

  .n .reveal-from-left {
    transform: translateX(-10px);
  }

  .n .reveal-from-right {
    transform: translateX(10px);
  }

  .n .reveal-scale-up {
    transform: scale(0.95);
  }

  .n .reveal-scale-down {
    transform: scale(1.05);
  }

  .n .reveal-rotate-from-left {
    transform: perspective(1000px) rotateY(-45deg);
  }

  .n .reveal-rotate-from-right {
    transform: perspective(1000px) rotateY(45deg);
  }

  .n.r [class*="reveal-"] {
    transition: opacity 0.8s cubic-bezier(0.39, 0.575, 0.565, 1),
      transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  .n.r [class*="reveal-"].is-revealed {
    opacity: 1;
    transform: translate(0);
  }
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

hr {
  border: 0;
  display: block;
  height: 1px;
  background: #2b2f40;
  margin-top: 24px;
  margin-bottom: 24px;
}

ul,
ol {
  margin-top: 0;
  padding-left: 24px;
}

ul:not(:last-child),
ol:not(:last-child) {
  margin-bottom: 24px;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li:not(:last-child) {
  margin-bottom: 12px;
}

li > ul,
li > ol {
  margin-top: 12px;
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 24px;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 24px;
  margin-bottom: 24px;
}

img {
  border-radius: 2px;
}

img,
svg,
video {
  display: block;
  height: auto;
}

figcaption {
  padding: 8px 0;
}

table {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  border-collapse: collapse;
  margin-bottom: 24px;
  width: 100%;
}

tr {
  border-bottom: 1px solid #2b2f40;
}

th {
  font-weight: 700;
  text-align: left;
}

th,
td {
  padding: 8px 16px;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

.i hr {
  display: block;
  height: 1px;
  background: #dde2f4;
}

.i tr {
  border-bottom: 1px solid #dde2f4;
}

html {
  font-size: 20px;
  line-height: 30px;
}

body {
  color: #ffffff;
  font-size: 1rem;
}

body,
button,
input,
select,
textarea {
  font-family: "Helvetica Now Display Medium", sans-serif;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.s,
.o,
.u,
.a,
.f,
.l {
  font-family: "Helvetica Now Display Bold", serif;
  font-weight: normal;
  clear: both;
  color: #dde2f4;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light,
p.light,
.s.light,
.o.light,
.u.light,
.a.light,
.f.light,
.l.light {
  color: #272937;
}

h1,
.s {
  font-size: 36px;
  line-height: 46px;
}

h2,
.o {
  font-size: 32px;
  line-height: 42px;
}

h3,
.u {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.1px;
}

h4,
.a {
  font-size: 24px;
  line-height: 27px;
  letter-spacing: -0.1px;
}

h5,
.f {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
}

h6,
.l,
small,
.c {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.1px;
}

.h {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
}

.p {
  font-size: 14px;
  line-height: 22px;
}

figcaption {
  font-size: 14px;
  line-height: 22px;
}

a:not(.tbuttonn) {
  color: #0f90ff;
}

a:not(.tbuttonn):hover,
a:not(.tbuttonn):active {
  outline: 0;
  text-decoration: none;
}

a.rbuttoni {
  color: #99a1ba;
}

h1,
.s {
  margin-top: 48px;
  margin-bottom: 32px;
}

h2,
.o {
  margin-top: 48px;
  margin-bottom: 32px;
}

h3,
.u {
  margin-top: 36px;
  margin-bottom: 24px;
}

h4,
h5,
h6,
.a,
.f,
.l {
  margin-top: 24px;
  margin-bottom: 24px;
}

p {
  margin-top: 0;
  margin-bottom: 24px;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
}

blockquote::before {
  content: "\201C";
}

blockquote::after {
  content: "\201D";
}

blockquote p {
  display: inline;
}

address {
  border-width: 1px 0;
  border-style: solid;
  border-color: #2b2f40;
  padding: 24px 0;
  margin: 0 0 24px;
}

pre,
pre h1,
pre h2,
pre h3,
pre h4,
pre h5,
pre h6,
pre .s,
pre .o,
pre .u,
pre .a,
pre .f,
pre .l {
  font-family: "Courier 10 Pitch", Courier, monospace;
}

pre,
code,
kbd,
tt,
var {
  background: #101119;
}

pre {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  max-width: 100%;
  overflow: auto;
  padding: 24px 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  padding: 2px 4px;
}

abbr,
acronym {
  cursor: help;
}

mark,
ins {
  text-decoration: none;
}

b,
strong {
  font-weight: 700;
}

.i {
  color: #70778e;
}

.i a:not(.tbuttonn) {
  color: #70778e;
}

.i a:not(.tbuttonn):hover,
.i a:not(.tbuttonn):active {
  outline: 0;
  text-decoration: none;
}

.i a.rbuttoni:hover,
.i a.rbuttoni:active {
  color: #0f90ff;
}

.i a.d:hover,
.i a.d:active {
  color: #0f90ff;
}

.i h1,
.i h2,
.i h3,
.i h4,
.i h5,
.i h6,
.i .s,
.i .o,
.i .u,
.i .a,
.i .f,
.i .l {
  color: #161822;
}

.i address {
  border-color: #dde2f4;
}

.i pre,
.i code,
.i kbd,
.i tt,
.i var {
  background: #101119;
}

@media (max-width: 640px) {
  .banner-texts {
    display: flex;
    align-items: flex-end !important;
    text-align: left !important;
  }

  .v {
    font-size: 36px;
    line-height: 46px;
  }

  .m {
    font-size: 32px;
    line-height: 42px;
  }

  .g {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.1px;
  }

  .y {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.1px;
  }

  .b {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.1px;
  }

  .w {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }

  .hlf {
    width: 100%;
  }

  .smartphone {
    max-width: 260px;
  }

  .readyButton {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .cta-action {
    flex-wrap: wrap;
    width: 100%;
  }

  .black-button {
    width: 100%;
  }
}

.home-title {
  font-size: 62px;
}

.product-title {
  font-size: 31px;
  line-height: 40.3px;
}

.tcenter {
  text-align: center;
}

.rz .card-item {
  background: transparent;

  padding: 30px;
  border: 2px solid #f3f4f9;
  border-radius: 15px;

  width: 340px;
  height: 100%;
}

.rz .card-item .card-title {
  font-size: 22px;
  color: #272937;
}

.rz .card-item .card-subtitle {
  font-size: 17px;
  color: #757893;
  margin-top: 10px;
}

.item-menu {
  position: relative;

  cursor: pointer;
}

.item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-content a {
  padding-right: 9.26px !important;
}

.sub-menu {
  display: none;
}

.sub-menu.visible {
  display: block;

  position: fixed;

  padding: 10px;
  margin-left: -30px;
}

.nk .sub-menu .sub-menu-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 240px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
}

.sub-menu-content li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  padding: 12px 16px;
  color: #272937;

  cursor: pointer;
  transition: all ease-out 0.3s;
}

.sub-menu-content li:hover {
  background-color: #f8f9fb;
  border-radius: 10px;
  transition: all ease-out 0.3s;
}

.sub-menu-content li p {
  margin-bottom: 0;
  margin-left: 15px;
  transition: all ease-out 0.3s;
}

.sub-menu-content li:hover p {
  margin-left: 20px;
  transition: all ease-out 0.3s;
}

@media (min-width: 641px) {
  h1,
  .s {
    font-size: 44px;
    line-height: 54px;
  }

  h2,
  .o {
    font-size: 36px;
    line-height: 46px;
  }

  h3,
  .u {
    font-size: 32px;
    line-height: 42px;
  }

  .hlf {
    width: 50%;
  }

  .readyButton {
    margin-right: 20px;
    margin-bottom: 0px;
  }
}
@media (max-width: 1199px) {
  .shadow-mobile {
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.tcontainern,
.rcontaineri,
.scontainero,
.ncontainer {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 481px) {
  .tcontainern,
  .rcontaineri,
  .scontainero,
  .ncontainer {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.full-container {
  width: 100%;
  padding: 0 24px;
}

.tcontainern {
  max-width: 1200px;
}

.ncontainer {
  max-width: 1350px;
}

.rcontaineri {
  max-width: 944px;
}

.scontainero {
  max-width: 668px;
}

.banner-content {
  max-width: 600px;
}

[class*="container"] [class*="container"] {
  padding-left: 0;
  padding-right: 0;
}

[class*="container"] .rcontaineri {
  max-width: 896px;
}

[class*="container"] .scontainero {
  max-width: 620px;
}

.tbuttonn {
  display: inline-flex;
  font-size: 15px;
  line-height: 22px;
  font-weight: normal;
  padding: 13px 30px;
  height: 50px;
  text-decoration: none !important;
  color: #272937;
  background-color: #dde2f4;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 7px;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background 0.15s ease;
}

.tbuttonn:active {
  outline: 0;
}

.tbuttonn:hover {
  background-color: #fcfcfe;
}

.tbuttonn.x {
  position: relative;
  color: transparent !important;
  pointer-events: none;
}

.tbuttonn.x::after {
  content: "";
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  margin-left: -11px;
  margin-top: -11px;
  top: 50%;
  left: 50%;
  border: 2px solid #0f90ff;
  border-radius: 50%;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  z-index: 1;
  -webkit-animation: button-loading 0.6s infinite linear;
  animation: button-loading 0.6s infinite linear;
}

.tbuttonn[disabled] {
  cursor: not-allowed;
  color: #161822;
  background-color: #3f455a !important;
}

.tbuttonn[disabled].x::after {
  border-color: #161822;
}

.sbuttono {
  padding: 13px 30px;
  height: 50px;
}

.ubuttona {
  color: #dde2f4;
  background-color: #161822;
}

.ubuttona:hover {
  background-color: #1c1f2b;
}

.ubuttona.x::after {
  border-color: #dde2f4;
}

.fbuttonl {
  color: #272937;
  background-color: #ffffff;
}

.fbuttonl-mobile {
  color: #ffffff;
  background-color: #272937 !important;
}

.fbuttonl:hover {
  background-color: #ffffff;
}

.fbuttonl.x::after {
  border-color: #dde2f4;
}

.cbuttonh {
  color: #dde2f4;
  background-color: #272937;
  height: 70px;
  width: 70px;
  padding: 19px 30px;
}

.cbuttonh:hover {
  background-color: #3bade4;
}

.cbuttonh.x::after {
  border-color: #dde2f4;
}

.pbuttond {
  display: flex;
  width: 100%;
}

.vbuttonm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -8px;
  margin-left: -8px;
  margin-top: -8px;
}

.vbuttonm:last-of-type {
  margin-bottom: -8px;
}

.vbuttonm:not(:last-of-type) {
  margin-bottom: 8px;
}

.vbuttonm > [class*="button"] {
  margin: 8px;
}

@media (max-width: 640px) {
  .gbuttony {
    width: 100%;
    max-width: 280px;
  }
}

@-webkit-keyframes button-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes button-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

.k,
._,
.j {
  color: #99a1ba;
  font-weight: 500;
}

.k {
  font-size: 14px;
  line-height: 22px;
}

.q,
.z,
.te input,
.tt input {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 1px;
  border-style: solid;
  border-color: #3f455a;
}

.q:hover,
.z:hover,
.te input:hover,
.tt input:hover {
  border-color: #70778e;
}

.q:active,
.q:focus,
.z:active,
.z:focus,
.te input:active,
.te input:focus,
.tt input:active,
.tt input:focus {
  outline: none;
  border-color: #0f90ff;
}

.q[disabled],
.z[disabled],
.te input[disabled],
.tt input[disabled] {
  cursor: not-allowed;
  background-color: #3f455a;
  border-color: #99a1ba;
}

.q.tn,
.z.tn,
.te input.tn,
.tt input.tn {
  border-color: #ee5860;
}

.q.tr,
.z.tr,
.te input.tr,
.tt input.tr {
  border-color: #e8a658;
}

.q.ti,
.z.ti,
.te input.ti,
.tt input.ti {
  border-color: #17c297;
}

.q,
.z {
  display: block;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  padding: 11px 15px;
  height: 48px;
  border-radius: 0;
  color: #dde2f4;
  box-shadow: none;
  max-width: 100%;
  width: 100%;
}

.q::-webkit-input-placeholder {
  color: #70778e;
}

.q:-ms-input-placeholder {
  color: #70778e;
}

.q::-ms-input-placeholder {
  color: #70778e;
}

.q::placeholder {
  color: #70778e;
}

.q::-ms-input-placeholder {
  color: #70778e;
}

textarea.q {
  height: auto;
  resize: vertical;
}

.z {
  padding-right: 46px;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%2011.4L2.6%206%204%204.6l4%204%204-4L13.4%206%208%2011.4z%22%20fill%3D%22%23DDE2F4%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
}

.ts {
  padding-right: 38px;
  background-position: right 11px center;
}

.ts,
.to {
  padding: 5px 11px;
  height: 36px;
}

.tu,
.ta {
  position: relative;
}

.tu svg,
.ta svg {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tu .q {
  padding-left: 46px;
}

.tu .q + svg {
  left: 15px;
}

.tu .to {
  padding-left: 38px;
}

.tu .to + svg {
  left: 11px;
}

.ta .q {
  padding-right: 46px;
}

.ta .q + svg {
  right: 15px;
}

.ta .to {
  padding-right: 38px;
}

.ta .to + svg {
  right: 11px;
}

.te,
.tt,
._ {
  cursor: pointer;
}

.te,
.tt,
._,
.j {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
}

.te,
.tt {
  color: #99a1ba;
}

.te,
.tt {
  position: relative;
  padding-left: 24px;
}

.te input,
.tt input {
  position: absolute;
  left: 0;
  top: 3px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.te input:checked,
.tt input:checked {
  background-color: #0f90ff !important;
  border-color: #0f90ff !important;
  background-position: 50%;
}

.te input {
  border-radius: 2px;
}

.te input:checked {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxwYXRoIGQ9Ik0xMS40IDVMMTAgMy42bC00IDQtMi0yTDIuNiA3IDYgMTAuNHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
}

.tt input {
  border-radius: 50%;
}

.tt input:checked {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBmaWxsPSIjRkZGIiBjeD0iNyIgY3k9IjciIHI9IjMiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
}

._ {
  display: inline-flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

._ input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

._ .tf {
  display: block;
  position: relative;
  min-width: 60px;
  width: 60px;
  height: 32px;
  border-radius: 16px;
  background: #3f455a;
}

._ .tf::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background: #dde2f4;
  transition: all 0.15s ease-out;
}

._ input:checked + .tf {
  background: #0f90ff;
}

._ input:checked + .tf::before {
  left: 30px;
}

._ > span:not(.tf) {
  order: -1;
  margin-right: 12px;
}

._ > span:not(.tf):last-child {
  order: initial;
  margin-left: 12px;
  margin-right: 0;
}

.j > span {
  display: block;
}

.j input[type="range"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #99a1ba;
  border-radius: 3px;
  height: 6px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  outline: none;
}

.j input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background-color: #0f90ff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23DDE2F4%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}

.j input[type="range"]::-moz-range-thumb {
  background-color: #0f90ff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23DDE2F4%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}

.j input[type="range"]::-ms-thumb {
  background-color: #0f90ff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23DDE2F4%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}

.j input[type="range"]::-moz-focus-outer {
  border: 0;
}

.i .k,
.i ._,
.i .j {
  color: #3f455a;
}

.i .q,
.i .z,
.i .te input,
.i .tt input {
  border-color: #dde2f4;
}

.i .q[disabled],
.i .z[disabled],
.i .te input[disabled],
.i .tt input[disabled] {
  background-color: #dde2f4;
}

.i .q,
.i .z {
  color: #3f455a;
}

.i .z {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%2011.4L2.6%206%204%204.6l4%204%204-4L13.4%206%208%2011.4z%22%20fill%3D%22%233F455A%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
}

.i .te,
.i .tt {
  color: #3f455a;
}

.i .te input:checked,
.i .tt input:checked {
  background-color: !important;
  border-color: !important;
}

.tl {
  display: flex;
}

.tl .q {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 12px;
}

.tl .tbuttonn {
  padding-left: 24px;
  padding-right: 24px;
  flex-shrink: 0;
}

.tc {
  font-size: 14px;
  line-height: 22px;
  margin-top: 8px;
}

@media (max-width: 640px) {
  .th .q {
    margin-bottom: 12px;
  }

  .th .tbuttonn {
    width: 100%;
  }
}

@media (min-width: 641px) {
  .th {
    display: flex;
  }

  .th .q {
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 12px;
  }

  .th .tbuttonn {
    padding-left: 24px;
    padding-right: 24px;
    flex-shrink: 0;
  }

  .title-blog {
    max-width: 50%;
  }
}

.tp {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
}

.td,
.tv {
  display: block;
  pointer-events: none;
}

.td {
  position: relative;
  width: 24px;
  height: 24px;
}

.tv,
.tv::before,
.tv::after {
  width: 24px;
  height: 2px;
  position: absolute;
  background: #ffffff;
}

.i .tv,
.i .tv::before,
.i .tv::after {
  background: #2b2f40;
}

.tv {
  top: 50%;
  margin-top: -1px;
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.tv::before,
.tv::after {
  content: "";
  display: block;
}

.tv::before {
  top: -7px;
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.tv::after {
  bottom: -7px;
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    width 0.1s 0.25s ease-in;
}

.tm .tv {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.tm .tv::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.tm .tv::after {
  width: 24px;
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    width 0.1s ease-out;
}

.tg li {
  position: relative;
}

.tg li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #2b2f40;
}

.tg li:last-of-type {
  position: relative;
}

.tg li:last-of-type::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #2b2f40;
}

.i .tg li {
  position: relative;
}

.i .tg li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #dde2f4;
}

.i .tg li:last-of-type {
  position: relative;
}

.i .tg li:last-of-type::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #dde2f4;
}

.ty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  cursor: pointer;
}

.ty span {
  width: calc(100% - (16px * 2));
}

.tb {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-in-out, opacity 0.15s;
}

.tw .tb {
  opacity: 1;
}

.tb p:last-child {
  margin-bottom: 24px;
}

.tx {
  position: relative;
  width: 16px;
  height: 16px;
}

.tx::before,
.tx::after {
  content: "";
  position: absolute;
  background: #0f90ff;
  transition: transform 0.25s ease-out;
}

.i .tx::before,
.i .tx::after {
  background: #0f90ff;
}

.tx::before {
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
}

.tx::after {
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
}

.tw .tx {
  cursor: pointer;
}

.tw .tx::before {
  transform: rotate(90deg);
}

.tw .tx::after {
  transform: rotate(180deg);
}

.tk,
.tk::before {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.tk {
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
}

.tk.t_ .tj {
  padding: 0;
  max-width: 1024px;
}

.tk::before,
.tk .tj {
  display: none;
}

.tk::before {
  content: "";
  position: absolute;
  background-color: rgba(22, 24, 34, 0.88);
}

.tk.tw {
  display: flex;
}

.tk.tw::before,
.tk.tw .tj {
  display: block;
}

.tk.tw .tj {
  -webkit-animation: slideUpInModal 0.15s ease-in-out both;
  animation: slideUpInModal 0.15s ease-in-out both;
}

.tk.tw::before {
  -webkit-animation: slideUpInModalBg 0.15s ease-in-out both;
  animation: slideUpInModalBg 0.15s ease-in-out both;
}

.tj {
  max-height: calc(100vh - 16px * 2);
  overflow: auto;
  position: relative;
  width: calc(100% - (16px * 2));
  max-width: 520px;
  margin-left: 16px;
  margin-right: 16px;
  background: #2b2f40;
}

.tq {
  padding: 48px 16px;
}

.tz {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 16px;
  height: 16px;
}

.tz::before,
.tz::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -8px;
  width: 16px;
  height: 2px;
  background: #70778e;
}

.tz::before {
  transform: rotate(225deg);
}

.tz::after {
  transform: rotate(-45deg);
}

.tz:hover::before,
.tz:hover::after {
  background: #99a1ba;
}

@media (max-width: 641px) {
  .tiles-wrap {
    justify-content: center !important;
  }
}

@media (min-width: 641px) {
  .tj {
    margin: 0 auto;
    max-height: calc(100vh - 48px * 2);
  }

  .tq {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

@-webkit-keyframes slideUpInModal {
  0% {
    opacity: 0;
    transform: translateY(-24px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUpInModal {
  0% {
    opacity: 0;
    transform: translateY(-24px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes slideUpInModalBg {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUpInModalBg {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ne.x:not(.tw) {
  display: block;
  position: absolute;
  visibility: hidden;
  opacity: 0;
}

.ne:not(.tw) {
  display: none !important;
}

.nt {
  display: inline-flex;
}

.nn {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  line-height: 0;
  border: none;
  background: transparent;
}

.nn::before {
  content: "";
  position: absolute;
  left: 6px;
  top: 6px;
  background: #3f455a;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transition: background 0.25s ease;
}

.nn:focus {
  outline: none;
}

.nn.tw::before {
  background: #70778e;
}

.nn:hover::before {
  background: #99a1ba;
}

.i .nn::before {
  background: #70778e;
}

.i .nn.tw::before {
  background: #3f455a;
}

.i .nn:hover::before {
  background: #0f90ff;
}

.nr {
  margin-top: -24px;
}

.nr:last-of-type {
  margin-bottom: -24px;
}

.nr:not(:last-of-type) {
  margin-bottom: 24px;
}

.ni {
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
}

.ni:not(:last-of-type)::before {
  content: "";
  display: block;
  position: absolute;
  top: 43px;
  left: 3px;
  width: 2px;
  height: calc(100% - 8px - 8px);
  background: #3f455a;
}

.i .ni:not(:last-of-type)::before {
  background: #2b2f40;
}

.ns {
  margin-left: 32px;
}

.no {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.no::before {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: -32px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #0f90ff;
}

.i .no::before {
  background: #70778e;
}

@media (min-width: 641px) {
  .nr {
    margin-top: -32px;
  }

  .nr:last-of-type {
    margin-bottom: -32px;
  }

  .nr:not(:last-of-type) {
    margin-bottom: 32px;
  }

  .ni {
    padding-top: 32px;
    padding-bottom: 32px;
    width: 50%;
  }

  .ni:not(:last-of-type)::before {
    top: 51px;
    left: -1px;
  }

  .ni:nth-child(odd) {
    left: 50%;
  }

  .ni:nth-child(odd) .no::before {
    left: -36px;
  }

  .ni:nth-child(even) {
    text-align: right;
  }

  .ni:nth-child(even):not(:last-of-type)::before {
    left: auto;
    right: -1px;
  }

  .ni:nth-child(even) .ns {
    margin-left: 0;
    margin-right: 32px;
  }

  .ni:nth-child(even) .no {
    position: relative;
  }

  .ni:nth-child(even) .no::before {
    left: auto;
    right: -36px;
  }
}

.nu {
  margin-top: -24px;
}

.nu:last-of-type {
  margin-bottom: 0;
}

.nu:not(:last-of-type) {
  margin-bottom: 24px;
}

.nu .split-item {
  padding-top: 24px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.nu .split-item .na {
  position: relative;
  width: 100%;
}

.nu .split-item .na img,
.nu .split-item .na svg,
.nu .split-item .na video {
  width: auto;
  height: auto;
  max-width: 100%;
  overflow: visible;
}

.nu .split-item .na.nf img,
.nu .split-item .na.nf svg,
.nu .split-item .na.nf video {
  width: 100%;
}

@media (max-width: 640px) {
  .nu .split-item .nl {
    margin-bottom: 32px;
  }

  .nu.nc .split-item .nl {
    order: 1;
    margin-bottom: 0 !important;
  }

  .nu.nc .split-item .na {
    margin-bottom: 32px;
  }
}

@media (min-width: 641px) {
  .nu {
    margin-top: -40px;
  }

  .nu:last-of-type {
    margin-bottom: 30px;
  }

  .nu:not(:last-of-type) {
    margin-bottom: 40px;
  }

  .nu .split-item {
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  .nu .split-item .nl {
    flex-shrink: 0;
    width: 466px;
    margin-bottom: 0;
    margin-right: 64px;
  }

  .nu .split-item .na {
    min-width: 436px;
  }

  .nu:not(.nh) .split-item:nth-child(even),
  .nu.nh .split-item:nth-child(odd) {
    justify-content: flex-end;
  }

  .nu:not(.nh) .split-item:nth-child(even) .nl,
  .nu.nh .split-item:nth-child(odd) .nl {
    order: 1;
    margin-left: 64px;
    margin-right: 0;
  }

  .nu:not(.nh) .split-item:nth-child(odd) .na img,
  .nu:not(.nh) .split-item:nth-child(odd) .na svg,
  .nu:not(.nh) .split-item:nth-child(odd) .na video,
  .nu.nh .split-item:nth-child(even) .na img,
  .nu.nh .split-item:nth-child(even) .na svg,
  .nu.nh .split-item:nth-child(even) .na video {
    margin-left: auto;
  }

  .nu.np .split-item {
    align-items: flex-start;
  }
}

@media (min-width: 821px) {
  .tcontainern .nu .split-item .nl {
    width: 488px;
  }

  .tcontainern .nu .split-item .na {
    min-width: 528px;
  }
}

.tiles-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-top: -12px;
}

.tiles-wrap:last-of-type {
  margin-bottom: -12px;
}

.tiles-wrap:not(:last-of-type) {
  margin-bottom: 12px;
}

.tiles-wrap.nd::after {
  content: "";
  flex-basis: 330px;
  max-width: 330px;
  box-sizing: content-box;
  padding-left: 12px;
  padding-right: 12px;
  height: 0;
}

.nv {
  flex-basis: 330px;
  max-width: 330px;
  box-sizing: content-box;
  padding: 12px;
}

.nv * {
  box-sizing: border-box;
}

.nm {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  height: 100%;
  padding: 32px 24px;
}

.ng {
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 10 !important;
}

.ng + .ny .nb:first-of-type {
  padding-top: 80px;
}

.ng .nw {
  margin-right: 32px;
}

.nx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.nk {
  flex-grow: 1;
}

.nk .n_ {
  display: flex;
  flex-grow: 1;
}

.nk ul {
  display: flex;
  align-items: center;
  flex-grow: 1;
  white-space: nowrap;
  margin-bottom: 0;
  justify-content: flex-start;
}

.nk ul:first-of-type {
  flex-wrap: wrap;
}

.nk li + .bbuttonw {
  margin-left: 32px;
}

.nk a:not(.tbuttonn) {
  display: block;
  color: #ffffff;
  text-decoration: none;
  font-weight: normal;
  padding: 0 24px;
}

.i .nk a:not(.tbuttonn) {
  color: #3f455a;
  text-decoration: none;
}

.i .nk a:not(.tbuttonn):hover,
.i .nk a:not(.tbuttonn):active {
  color: #ffffff;
}

.nj:first-of-type {
  flex-grow: 1;
  justify-content: flex-end;
}

.nq {
  justify-content: flex-end;
}

.nq + .nq {
  flex-grow: 0;
}

.tp {
  display: none;
}

.nx input {
  display: none;
}

@media (max-width: 640px) {
  .tp {
    display: block;
  }

  .tp + .nk {
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 9999;
    background: #ffffff;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out, opacity 0.15s;
  }

  .nx input:checked ~ .nk {
    opacity: 1;
    max-height: unset;
    margin: 0 20px 20px;
    border-radius: 15px;
  }

  .tp + .nk .n_ {
    flex-direction: column;
    padding: 24px;

    display: none;
  }

  .tp + .nk ul {
    display: block;
    text-align: center;
  }

  .tp + .nk ul a:not(.tbuttonn) {
    display: inline-flex;
    color: #272937;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .tp + .nk a.tbuttonn {
    margin-left: 0;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .nv {
    max-width: 330px;
    box-sizing: content-box;
    padding: 12px;
  }

  .nv.mobile {
    height: 280px;
  }
}

.nz {
  background: #1a1c28;
}

.nz .re {
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 20px 48px rgba(22, 24, 34, 0.8);
}

.nz .re::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161822;
  z-index: -10;
}

.re {
  background: #161822;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.re.light {
  background: #ffffff;
}

.ny {
  flex: 1 0 auto;
}

.rt {
  position: relative;
}

.rt::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161822;
  z-index: -3;
}

.rn {
  padding: 48px 0;
}

.rr {
  padding-bottom: 12px;
}

.ri {
  padding-top: 12px;
}

.rs {
  display: flex;
  flex-wrap: wrap;
  margin-right: -24px;
  margin-left: -24px;
  margin-top: -12px;
}

.rs:last-of-type {
  margin-bottom: 28px;
}

.rs:not(:last-of-type) {
  margin-bottom: 12px;
}

.ro {
  flex-grow: 1;
  flex-basis: 160px;
  padding: 12px 24px;
}

.ro * {
  box-sizing: border-box;
}

.ro ul li {
  margin-bottom: 4px;
}

.ro a {
  color: #99a1ba;
  text-decoration: none;
}

.ro a.light {
  color: #3a4154;
}

.ro a:hover,
.ro a:active {
  color: #3a4154;
}

.i .ro a {
  text-decoration: none;
}

.ru {
  font-size: 14px;
  line-height: 22px;
  font-family: "Helvetica Now Display Bold", sans-serif;
  font-weight: normal;
  color: #dde2f4;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.ru.light {
  color: #272937;
}

.i .ru {
  color: #161822;
}

.ra {
  margin-bottom: 13px;
}

.ra a,
.rf a {
  display: flex;
}

.ra ul,
.rf ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 0;
}

.ra ul {
  margin-right: -16px;
  margin-left: -16px;
}

.ra li {
  padding: 0 8px;
}

.ra a {
  padding: 8px;
}

.ra a svg {
  fill: #3a4154;
  transition: fill 0.15s ease;
}

.ra a:hover svg {
  fill: #0f90ff;
}

.rf {
  margin-right: -12px;
  margin-left: -12px;
}

.rf a {
  color: #99a1ba;
  text-decoration: none;
  padding: 0 12px;
}

.rf a:hover,
.rf a:active {
  color: #0f90ff;
}

.i .rf a {
  text-decoration: none;
}

@media (max-width: 640px) {
  .rr > *:not(:last-child),
  .ri > *:not(:last-child) {
    margin-bottom: 24px;
  }
}

@media (min-width: 641px) {
  .rn {
    padding: 64px 0 0 0;
  }

  .rr.rl,
  .ri.rl {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .rr.rc > *:first-child,
  .ri.rc > *:first-child {
    order: 1;
  }
}

.rh {
  position: relative;
  padding-top: 48px;
}

.rp {
  padding-bottom: 38px;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (min-width: 641px) {
  .rh {
    padding-top: 80px;
    text-align: left;
  }

  .align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .rp {
    padding-bottom: 48px;
  }
}

.rd > .rv + .rm,
.rd > .rm + .rv {
  margin-top: 48px;
}

.rd > .rm > a {
  display: inline-flex;
  align-items: center;
  vertical-align: top;
}

@media (max-width: 640px) {
  .rg .nu .split-item .nl {
    margin-bottom: 48px;
  }

  .rg .nu.nc .split-item .na {
    margin-bottom: 48px;
  }
}

@media (min-width: 641px) {
  .rd > .rv + .rm,
  .rd > .rm + .rv {
    margin-top: 64px;
  }
}

@media (min-width: 641px) {
  .ry .rp {
    padding-bottom: 92px;
  }
}

.rb .tiles-wrap {
  margin-right: -16px;
  margin-left: -16px;
  margin-top: -16px;
}

.rb .tiles-wrap:last-of-type {
  margin-bottom: -16px;
}

.rb .tiles-wrap:not(:last-of-type) {
  margin-bottom: 16px;
}

.rb .tiles-wrap.nd::after {
  flex-basis: 317px;
  max-width: 317px;
  padding-left: 16px;
  padding-right: 16px;
}

.rb .nv {
  flex-basis: 317px;
  max-width: 317px;
}

.rb .nm {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 641px) {
  .rb .tiles-wrap {
    margin-right: -32px;
    margin-left: -32px;
    margin-top: -32px;
  }

  .rb .tiles-wrap:last-of-type {
    margin-bottom: -32px;
  }

  .rb .tiles-wrap:not(:last-of-type) {
    margin-bottom: 32px;
  }

  .rb .tiles-wrap.nd::after {
    padding-left: 32px;
    padding-right: 32px;
  }

  .rb .nv {
    padding: 25px 32px 32px 32px;
  }
}

.rw .tiles-wrap.nd::after {
  flex-basis: 344px;
  max-width: 344px;
}

.rw .nv {
  flex-basis: 344px;
  max-width: 344px;
}

.rw .nm {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.rx {
  flex-grow: 1;
  width: 100%;
}

ul.rk li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.r_,
.rj {
  margin-bottom: 48px;
}

.rj {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.rj input {
  width: 100%;
}

.rj .rq {
  position: absolute;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #70778e;
  margin-top: 8px;
  --t: 36px;
}

.i .rq {
  color: #70778e;
}

@media (min-width: 641px) {
  .r_,
  .rj {
    margin-top: -32px;
    margin-bottom: 64px;
  }
}

.rz .tiles-wrap.nd::after {
  flex-basis: 344px;
  max-width: 344px;
}

.rz .nv {
  flex-basis: 340px;
  max-width: 340px;
}

.rz .nm {
  padding-top: 20px;
  padding-bottom: 24px;
  padding-left: 25px;
  padding-right: 25px;
}

.ie {
  flex-grow: 1;
}

.it {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}

.in .tiles-wrap {
  margin-right: -16px;
  margin-left: -16px;
  margin-top: -16px;
}

.in .tiles-wrap:last-of-type {
  margin-bottom: -16px;
}

.in .tiles-wrap:not(:last-of-type) {
  margin-bottom: 16px;
}

.in .tiles-wrap.nd::after {
  flex-basis: 296px;
  max-width: 296px;
  padding-left: 16px;
  padding-right: 16px;
}

.in .nv {
  flex-basis: 296px;
  max-width: 296px;
  padding: 16px;
}

.in .nm {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 641px) {
  .in .rp {
    padding-bottom: 80px;
  }

  .in .tiles-wrap {
    margin-right: -32px;
    margin-left: -32px;
    margin-top: -32px;
  }

  .in .tiles-wrap:last-of-type {
    margin-bottom: -32px;
  }

  .in .tiles-wrap:not(:last-of-type) {
    margin-bottom: 32px;
  }

  .in .tiles-wrap.nd::after {
    padding-left: 32px;
    padding-right: 32px;
  }

  .in .nv {
    padding: 32px;
  }
}

.ir .rh {
  padding-top: 48px;
  padding-bottom: 48px;
}

.ii {
  margin-bottom: 40px;
}

@media (min-width: 641px) {
  .ir .rh {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .is {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .is .ii {
    margin-bottom: 0;
    margin-right: 48px;
  }
}

.io .rh {
  padding-top: 26px;
  padding-bottom: 26px;
}

.iu ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.iu li {
  padding: 12px 28px;
}

@media (min-width: 641px) {
  .io .iu ul {
    flex-direction: row;
  }
}

.ia .rp {
  padding-bottom: 32px;
}

.ia .nm {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 641px) {
  .ia .rp {
    padding-bottom: 48px;
  }
}

.if {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

[class*="illustration-"] {
  position: relative;
}

[class*="illustration-"]::after {
  pointer-events: none;
}

[class*="illustration-element-"]::after {
  z-index: -1;
}

[class*="illustration-section-"]::after {
  z-index: -2;
}

.tillustration-section-n::after {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 362px;
  background-repeat: no-repeat;
  background-size: auto;
  left: 50%;
  top: 0;
  background-position: center top;
  transform: translate(-50%);
}

.tillustration-element-n::after {
  content: "";
  position: absolute;
  width: 150%;
  max-width: 150%;
  height: 150%;
  background-repeat: no-repeat;
  background-size: contain;
  left: 50%;
  background-position: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

.tbuttonn {
  font-family: "Helvetica Now Display Bold", serif;
  font-weight: normal;
  box-shadow: 0 8px 16px rgba(22, 24, 34, 0.12);
}

.tbuttonn:not(.ubuttona):not(.fbuttonl):not(.cbuttonh) {
  background: white;
  background-origin: border-box;
}

.tbuttonn:not(.ubuttona):not(.fbuttonl):not(.cbuttonh):hover {
  background-color: #fcfcfe;
}

@media (min-width: 641px) {
  .n .ni:nth-child(even) .il.greveal-y {
    transform: translateX(-10px);
  }

  .n .ni:nth-child(odd) .il.greveal-y {
    transform: translateX(10px);
  }

  .n.r .ni .il.is-revealed {
    transform: translate(0);
  }
}

.nk a:not(.tbuttonn) {
  font-family: "Helvetica Now Display Bold", serif;
  font-size: 15px;
  font-weight: normal;
}

.ic {
  display: inline-flex;
  border-radius: 50%;
  background-color: #2b2f40;
}

.rw .nm {
  background: #2b2f40;
}

.ih {
  position: relative;
}

.ih::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #2b2f40;
}

.ih::after {
  background: #3f455a;
  max-width: 88px;
}

.ip {
  color: #99a1ba;
}

ul.rk {
  position: relative;
}

ul.rk::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #2b2f40;
}

ul.rk::after {
  background: #3f455a;
}

ul.rk li {
  margin-bottom: 0;
  padding: 14px 0;
  position: relative;
}

ul.rk li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #2b2f40;
}

ul.rk li::before {
  background: #3f455a;
}

ul.rk li::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5%2011h14v2H5z%22%20fill%3D%22%2370778E%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  order: -1;
}

ul.rk li.id::after {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20fill-opacity%3D%22.56%22%20fill%3D%22%2317C297%22%20opacity%3D%22.36%22%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%2212%22%2F%3E%3Cpath%20fill%3D%22%235DD2B5%22%20d%3D%22M10.5%2012.267l-2.5-1.6-1%201.066L10.5%2016%2017%209.067%2016%208z%22%2F%3E%3C%2Fsvg%3E");
}

.rz .nv {
  position: relative;
  padding-top: 50px;
}

.rz .nm {
  padding-top: 52px;
  background: #2b2f40;
}

.rz .nm.light {
  background: #f3f4f9;
  border-radius: 15px;
}

.rz .iv {
  position: absolute;
  top: 33px;
}

.rz .iv img {
  position: relative;
}

.im {
  font-family: "Helvetica Now Display Medium", serif;
  font-weight: normal;
}

.im.ig::before {
  background: #3f455a;
}

.iy {
  color: #0f90ff;
}

.iy a {
  color: #0f90ff;
}

.ib {
  padding-left: 16px;
  padding-right: 16px;
  background-color: #0f90ff;
  border-radius: 15px;
  /* background: -webkit-gradient(linear, left bottom, right top, color-stop(0, #0F90FF), to(#2742CC));
  background: linear-gradient(to right top, #0F90FF 0, #2742CC 100%) */
}

.ib h3 {
  color: white;
}

@media (min-width: 641px) {
  .ib {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.iw {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.ix {
  list-style: none;
  padding: 0;
}

.ix li {
  margin: 0;
}

.ik {
  text-align: left;
}

.i_ {
  text-align: center;
}

.ij {
  text-align: right;
}

.iq {
  font-weight: 400;
}

.iz {
  font-weight: 500;
}

.se {
  font-weight: normal;
}

.st {
  font-weight: 700;
}

.sn {
  color: #dde2f4;
}

.sr {
  color: #99a1ba;
}

.si {
  color: #70778e;
}

.ss {
  color: #0f90ff;
}

.so {
  color: #2ea7e2;
}

.su {
  color: #ee5860;
}

.sa {
  color: #e8a658;
}

.sf {
  color: #17c297;
}

.i .sn {
  color: #161822;
}

.i .sr {
  color: #2b2f40;
}

.i .si {
  color: #70778e;
}

.sl {
  text-transform: uppercase;
}

.sc {
  width: 100%;
}

.sh {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 96px);
  max-width: 100vw;
}

.sp {
  position: relative;
  background-color: #fff;
  z-index: 0;
}

.sd {
  box-shadow: 0 24px 64px rgba(22, 24, 34, 0.64);
}

.sd.light {
  box-shadow: 0 24px 64px rgba(245, 249, 252, 0.64);
}

img.sd {
  border-radius: 2px;
}

.ig {
  position: relative;
}

.sv {
  position: relative;
}

.i .ig {
  position: relative;
}

.i .ig::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #dde2f4;
}

.i .sv {
  position: relative;
}

.i .sv::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #dde2f4;
}

.sm {
  text-align: center;
}

.sm img,
.sm svg,
.sm video {
  margin-left: auto;
  margin-right: auto;
}

.sm .vbuttonm {
  justify-content: center;
}

.sg {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.sg.sy {
  padding-bottom: 75%;
}

.sg iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sb {
  margin: 0;
}

.sw {
  margin-top: 0;
}

.sx {
  margin-right: 0;
}

.sk {
  margin-bottom: 0;
}

.s_ {
  margin-left: 0;
}

.sj {
  margin: 4px;
}

.sq {
  margin-top: 4px;
}

.sz {
  margin-right: 4px;
}

.oe {
  margin-bottom: 4px;
}

.ot {
  margin-left: 4px;
}

.on {
  margin: 8px;
}

.or {
  margin-top: 8px;
}

.oi {
  margin-right: 8px;
}

.os {
  margin-bottom: 8px;
}

.oo {
  margin-left: 8px;
}

.ou {
  margin: 12px;
}

.oa {
  margin-top: 12px;
}

.of {
  margin-right: 12px;
}

.ol {
  margin-bottom: 12px;
}

.oc {
  margin-left: 12px;
}

.oh {
  margin: 16px;
}

.op {
  margin-top: 16px;
}

.od {
  margin-right: 16px;
}

.ov {
  margin-bottom: 16px;
}

.om {
  margin-left: 16px;
}

.og {
  margin: 24px;
}

.oy {
  margin-top: 24px;
}

.ob {
  margin-right: 24px;
}

.ow {
  margin-bottom: 24px;
}

.ox {
  margin-left: 24px;
}

.ok {
  margin: 32px;
}

.o_ {
  margin-top: 32px;
}

.oj {
  margin-right: 32px;
}

.oq {
  margin-bottom: 32px;
}

.oz {
  margin-left: 32px;
}

.ue {
  padding: 0;
}

.ut {
  padding-top: 0;
}

.un {
  padding-right: 0;
}

.ur {
  padding-bottom: 0;
}

.ui {
  padding-left: 0;
}

.us {
  padding: 4px;
}

.uo {
  padding-top: 4px;
}

.uu {
  padding-right: 4px;
}

.ua {
  padding-bottom: 4px;
}

.uf {
  padding-left: 4px;
}

.ul {
  padding: 8px;
}

.uc {
  padding-top: 8px;
}

.uh {
  padding-right: 8px;
}

.up {
  padding-bottom: 8px;
}

.ud {
  padding-left: 8px;
}

.uv {
  padding: 12px;
}

.um {
  padding-top: 12px;
}

.ug {
  padding-right: 12px;
}

.uy {
  padding-bottom: 12px;
}

.ub {
  padding-left: 12px;
}

.uw {
  padding: 16px;
}

.ux {
  padding-top: 16px;
}

.uk {
  padding-right: 16px;
}

.u_ {
  padding-bottom: 16px;
}

.uj {
  padding-left: 16px;
}

.uq {
  padding: 24px;
}

.uz {
  padding-top: 24px;
}

.ae {
  padding-right: 24px;
}

.at {
  padding-bottom: 24px;
}

.an {
  padding-left: 24px;
}

.ar {
  padding: 32px;
}

.ai {
  padding-top: 32px;
}

.as {
  padding-right: 32px;
}

.ao {
  padding-bottom: 32px;
}

.au {
  padding-left: 32px;
}

.aa {
  padding-top: 4px;
}

.af {
  padding-top: 8px;
}

.al {
  padding-top: 12px;
}

.ac {
  padding-top: 16px;
}

.ah {
  padding-top: 24px;
}

.ap {
  padding-top: 32px;
}

.ad {
  padding-top: 48px;
}

.av {
  padding-top: 64px;
}

@media (max-width: 640px) {
  .am {
    text-align: left;
  }

  .ag {
    text-align: center;
  }

  .ay {
    text-align: right;
  }

  .ab {
    text-align: center;
  }

  .button-otc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ab img,
  .ab svg,
  .ab video {
    margin-left: auto;
    margin-right: auto;
  }

  .ab .vbuttonm {
    justify-content: center;
  }

  .aw {
    padding-top: 4px;
  }

  .ax {
    padding-top: 8px;
  }

  .ak {
    padding-top: 12px;
  }

  .a_ {
    padding-top: 16px;
  }

  .aj {
    padding-top: 24px;
  }

  .aq {
    padding-top: 32px;
  }

  .az {
    padding-top: 48px;
  }

  .fe {
    padding-top: 64px;
  }
}

@media (min-width: 641px) {
  .ft {
    text-align: left;
  }

  .fn {
    text-align: center;
  }

  .fr {
    text-align: right;
  }

  .fi {
    text-align: center;
  }

  .fi img,
  .fi svg,
  .fi video {
    margin-left: auto;
    margin-right: auto;
  }

  .fi .vbuttonm {
    justify-content: center;
  }
}

.field.has-addons {
  display: flex;
  justify-content: flex-start;
}

.has-text-centered {
  text-align: center !important;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.control {
  font-size: 15px;
  position: relative;
  text-align: left;
}

.lang-selection .button {
  border-radius: 150px 0 0 150px !important;
  display: flex;
}

.lang-selection .button .icon {
  padding: 3px;
}

.button .icon:first-child:last-child {
  margin-left: calc(-0.375em - 1px);
  margin-right: calc(-0.375em - 1px);
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
}

.select:not(.is-multiple) {
  height: 2.25em;
}

.select {
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top;
}

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  box-shadow: none;
  display: inline-flex;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
  background-color: #fff;
  border-color: #dbdbdb;
  color: #363636;
  cursor: pointer;
  display: block;
  font-size: 1em;
  max-width: 100%;
  outline: none;
}

.select:not(.is-multiple):after {
  border: 1px solid #3273dc;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.5em;
  pointer-events: none;
  position: absolute;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 0.5em;
  margin-top: -0.375em;
  right: 1.125em;
  top: 50%;
  z-index: 4;
}

.aso img {
  -webkit-filter: grayscale(1) opacity(0.7);
          filter: grayscale(1) opacity(0.7);
  transition: 0.3s;
  width: 200px;
  mix-blend-mode: multiply;
}

.dark .aso img {
  mix-blend-mode: difference;
}

.aso img:hover {
  -webkit-filter: grayscale(0) opacity(1);
          filter: grayscale(0) opacity(1);
  transition: 0.3s;
  mix-blend-mode: normal;
}

.apt {
  margin-top: 20px;
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
}

.bbg {
  background: url(/static/media/bg-cappay.83c7f779.jpg);
  background-size: cover;
  padding: 50px 0;
  background-position: center center !important;
}

.bbg .light {
  color: white !important;
}

.bbg .subtitle.light {
  border-bottom: 1px solid white !important;
}

.bbg a {
  color: #272937;
  font-size: 16px;
  text-decoration: underline;
  margin-top: 30px;
  padding: 15px 20px;
  height: 55px;
}

.dark img:not(".ndc") {
  -webkit-filter: contrast(0.8);
          filter: contrast(0.8);
}

.mfc {
  min-width: 210px;
}

.mpt {
  text-shadow: 2px 3px 11px rgb(0, 0, 0);
  font-family: ;
}

.subtitleContainer {
  width: 100%;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 18px;
  color: #dde2f4;
  border-bottom: 1px solid #dde2f4;
  padding-bottom: 1em;
}

.subtitle.light {
  color: #272937;
  border-bottom: 1px solid #272937;
}

.blp a {
  text-decoration: none;
}

.blp img {
  width: 300px;
  height: 200px;
  transition: 0.3s;
  border-radius: 15px;
}

.blp a:hover img {
  transition: 0.3s;
  box-shadow: 0px 0px 20px rgba(111, 127, 136, 0.5);
}

.blp .sb.c {
  font-size: 12px;
  line-height: 18px;
}

.slick-prev {
  left: -13px !important;
}

.slick-next {
  right: -11px !important;
}

.slick-slide img {
  display: block;
  margin: auto;
}

.black-button {
  font-family: "Helvetica Now Display Bold", sans-serif;
  font-weight: normal;
  display: inline-flex;
  font-size: 15px;
  line-height: 22px;
  padding: 15px 30px;
  height: 55px;
  text-decoration: none !important;
  color: #ffffff !important;
  background-color: #272937;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 9px;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background 0.15s ease;
}

.black-button.outline {
  background-color: #ffffff;
  border: 2px solid #272937;
  color: #272937 !important;
}

.cta-action {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
}

.text-shadow {
  text-shadow: 1px 1px 10px #11153829;
}

.font-regular {
  font-family: "Helvetica Now Display Regular", sans-serif;
}
.ln-25 {
  line-height: 25px;
}

.line-26 {
  line-height: 26px;
}

.gray {
  color: #757893 !important;
}

.ft17 {
  font-size: 17px !important;
}

.mt33 {
  margin-top: 33px !important;
}

.ft16 {
  font-size: 16px !important;
}

.mb18 {
  margin-bottom: 18px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.darker {
  color: #272937 !important;
}

.has-text-centered {
  text-align: center !important;
}

.has-container-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.w100 {
  width: 100%;
}

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(39, 41, 55, 0.5);
  z-index: 999;
}

.modal.visible {
  display: block;
}

.modal-content2 {
  width: 500px;
  height: 100%;
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  z-index: 99999;
}

.gray {
  color: #757893 !important;
}

.darkBlue {
  color: #0f90ff !important;
}

.rr {
  padding-bottom: 12px;
}

.rs {
  display: flex;
  flex-wrap: wrap;
  margin-top: -12px;
}

.rs:last-of-type {
  margin-bottom: 28px;
}

.ro.more-padding {
  flex-basis: 333px;
  padding: 12px 90px 12px 0;
}

.footer-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}

.false-select {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

@media (max-width: 700px) {
  .ro.more-padding {
    padding: 12px 35px 12px 30px;
  }

  .footer-items {
    flex-direction: column;
    justify-content: center;
    text-align: center !important;
  }

  .false-select {
    margin-bottom: 30px;
  }

  .modal-content2 {
    width: 100%;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.ra ul {
  margin-right: -16px;
  margin-left: -16px;
}

.ra ul,
.rf ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 0;
}

.ra li {
  padding: 5px 6px;
  background-color: #fff;
  border-radius: 10px;
  margin: 8px !important;
}

.sd2 {
  box-shadow: 2px 5px 5px rgb(22 24 34 / 10%);
}

.ro {
  flex-grow: 1;
  flex-basis: 160px;
  padding: 12px 24px;
}

.control {
  font-size: 15px;
  position: relative;
  text-align: left;
}

.false-select a {
  color: #99a1ba;
  cursor: pointer;
}

.false-select a.active {
  color: #272937;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  margin: 25px 0;
  cursor: pointer;
}

.title-modal {
  width: 60%;
  font-size: 28px;
  line-height: 34px;
}

.description-modal,
.tip-modal {
  width: 85%;
  font-size: 14px;
  line-height: 22px;
  margin: 30px 0 60px;
}

.tip-modal {
  margin: 60px 0 0;
}

.modal-menu-container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  padding: 22px 20px;
  z-index: 999999;
  overflow-y: auto;
}

.header-modal-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.close-button-modal {
  padding: 6px;
  border: 1px solid #c3c6db;
  border-radius: 50px;

  cursor: pointer;
}

.product-link {
  color: #0f90ff;
  font-size: 17px;
  font-weight: 700;
  line-height: 22.95px;
}

.accordion-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border: none !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom: 1px solid #d9d9d9 !important;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.accordion-item a {
  font-size: 17px;
  color: #272937;
}

.accordion-item a:hover {
  color: #272937;
}

.accordion-menu {
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9 !important;
}

@media (max-width: 1150px) {
  .rz .nv {
    max-width: 509px;
  }

  .rz .card-item {
    width: 509px;
  }
}

@media (max-width: 1120px) {
  .rz .nv {
    max-width: 430px;
  }

  .rz .card-item {
    width: 430px;
  }
}

@media (max-width: 1100px) {
  .rz .nv {
    max-width: 459px;
  }

  .rz .card-item {
    width: 459px;
  }
}

@media (max-width: 1000px) {
  .rz .nv {
    max-width: 400px;
  }

  .rz .card-item {
    width: 400px;
  }
}

@media (max-width: 991px) {
  .rz .nv {
    max-width: 409px;
  }

  .rz .card-item {
    width: 396px;
  }
}

@media (max-width: 880px) {
  .rz .nv {
    max-width: 350px;
  }

  .rz .card-item {
    width: 350px;
  }
}

@media (max-width: 850px) {
  .rz .nv {
    max-width: 330px;
  }

  .rz .card-item {
    width: 330px;
  }
}

@media (max-width: 790px) {
  .rz .nv {
    max-width: 300px;
  }

  .rz .card-item {
    width: 300px;
  }
}

@media (max-width: 730px) {
  .rz .nv {
    max-width: 280px;
  }

  .rz .card-item {
    width: 280px;
  }
}

@media (max-width: 663px) {
  .rz .nv {
    max-width: 270px;
  }

  .rz .card-item {
    width: 270px;
  }
}

@media (max-width: 643px) {
  .rz .nv {
    max-width: 400px;
  }

  .rz .card-item {
    width: 400px;
  }
}

@font-face {
  font-family: "Helvetica Now Display Bold";
  src: local("HelveticaNowDisplay-Bold"),
    url(/static/media/HelveticaNowDisplay-Bold.be1fe313.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica Now Display Medium";
  src: local("HelveticaNowDisplay-Medium"),
    url(/static/media/HelveticaNowDisplay-Medium.53b5d05a.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica Now Display Regular";
  src: local("HelveticaNowDisplay-Regular"),
    url(/static/media/HelveticaNowDisplay-Regular.9632144b.ttf) format("truetype");
}

@font-face {
  font-family: "Aeonik Regular";
  src: local("Aeonik-Regular"), url(/static/media/Aeonik-Regular.844324dc.otf)  format("opentype");
}

@font-face {
  font-family: "Aeonik Medium";
  src: local("Aeonik-Medium"), url(/static/media/Aeonik-Medium.79b61679.otf)  format("opentype");
}

@font-face {
  font-family: "Aeonik Bold";
  src: local("Aeonik-Bold"), url(/static/media/Aeonik-Bold.3ab5a630.otf) format("opentype");
}


